<template>
  <ul>
    <li v-for="item in folderList" :key="item.key">
      <p
          :class="activeFolder==item.folder_id ? 'active' : ''"
          :style="{ 'padding-left': getPadding(item) }"
          @click.self="openFolder(item)"
          @click.right.stop.prevent="openContext($event, 'folder', item)"
      >
        <button v-if="item.haveChild" @click="item.openChild = !item.openChild" :style="{ left: getPadding(item, 'icon'), width: item.openChild?'16px':'12px' }">
          <f-icon :icon="item.openChild ? 'chevron-down' : 'chevron-right'"/>
        </button>
        <f-icon icon="folder" style="color: #8a8b8c;"/>
        {{ item.folder_name }}
      </p>
      <i v-if="item.openChild" class="line" :style="{left: getPadding(item, 'line')}"></i>
      <FolderList v-if="item.haveChild && item.openChild" :parent="item.folder_id" :open="item.openChild" :depth="depth + 1" :active-folder-prop="activeFolder"/>
<!--      <p-->
<!--          :class="activeFolder==item.folder_id ? 'active' : ''"-->
<!--          :style="{ 'padding-left': getPadding(item) }"-->
<!--          draggable="true"-->
<!--          @drop.stop.prevent="uploadFile($event, item)"-->
<!--          @dragover.stop.prevent-->
<!--          @dragstart="dragStart($event, item)"-->
<!--          @dragend="dragEnd($event)"-->
<!--          @click.self="openFolder(item)"-->
<!--          @click.right.stop.prevent="openContext($event, 'folder', item)">-->
<!--        <i v-if="depth > 1" class="line" :style="{ left: getPadding(item, 'line')}"></i>-->
<!--        <button v-if="item.children && item.children.length" @click="item.openChild = !item.openChild" :style="{ left: getPadding(item, 'icon') }">-->
<!--          <f-icon :icon="item.openChild ? 'caret-down' : 'caret-right'"/>-->
<!--        </button>{{ item.folder_name }}-->
<!--      </p>-->

    </li>

    <li v-for="file in fileList" :key="file.key"
        @click.right.stop.prevent="openContext($event, 'file', file)">
      <p :style="{ 'padding-left': getPadding(file) }">
        <i v-if="depth > 1" class="line" :style="{ left: getPadding(file, 'fileline')}"></i>
        <span class="ico_file_list">
          <file-icon :file_type="file.file_type" :key="file.file_id"/>
        </span>
        {{file.ori_name}}
      </p>
    </li>
  </ul>
</template>

<script>
import {EventBus} from "@/utils/EventBus";
import APIUtils from "@/utils/Network/APIUtils";

export default {
  name: "FolderList",
  props: ["parent", "depth", "open", "activeFolderProp"],
  data(){
    return{
      activeFolder : "",
      folderList : [],
      fileList : [],
      isOpen : false,
    }
  },
  created(){
    let objThis = this
    this.isOpen = this.open
    this.activeFolder = this.activeFolderProp
    EventBus.$on("bus:openFolder", function(folder_id){
      objThis.activeFolder = folder_id
    })
    EventBus.$on("bus:refreshWindow", function(folder_id){
      if(objThis.parent == folder_id)
        objThis.refresh(folder_id)
    })
  },
  watch:{
    isOpen(val){
      if(val){
        APIUtils.window_list({folder_id: this.parent}).then(res=>{
          this.folderList = res.body.folderList.map(el=>{
            el.openChild = false
            return el
          })
          this.fileList = res.body.fileList
        })
      }else{
        this.folderList = []
        this.fileList = []
      }
    }
  },
  methods:{
    openContext(e, type, item){
      EventBus.$emit("bus:openContext", {x: e.pageX, y: e.pageY, type: type, target: e.target, item: item})
    },
    openFolder(item){
      EventBus.$emit("bus:openFolder", item.folder_id)
    },
    refresh(folder_id){
      APIUtils.window_list({folder_id}).then(res=>{
        let newList = res.body.folderList.map(el=>{
          let ori = this.folderList.filter(i=>i.folder_id==el.folder_id)
          el.openChild = ori.length ? ori[0].openChild : false
          return el
        })
        this.folderList = newList
        this.fileList = res.body.fileList
      })
    },
    getPadding(item, target){
      const def = 20 - (item.folder_id == this.activeFolder ? 3 : 0)
      const dPadding = 14
      const iPadding = 18
      if(target == "icon")
        return (this.depth * dPadding - iPadding + def + (item.openChild ? -1 : 0)) + "px"
      else if(target == "line")
        return ((this.depth) * dPadding - iPadding + 6 + def) + "px"
      else if(target == "fileline")
        return ((this.depth-1) * dPadding - iPadding + 26) + "px"
      else{
        if(!item.children || item.children.length == 0){
          return (this.depth * dPadding + def) + "px"
        }else{
          return (this.depth * dPadding + def) + "px"
        }
      }
    },
    // uploadFile(e, t){
    //   if(e.dataTransfer.files.length){
    //     console.log("파일 업로드",e.dataTransfer.files)
    //   }else{
    //     console.log("폴더 이동", e.dataTransfer.getData('text') + " -> " + t.folder_name)
    //   }
    // },
    // dragStart(e, t){
    //   e.dataTransfer.setData('text', t.folder_name);
    //   e.target.style.opacity = 0.5;
    //   // if(t == "파일"){ //드래그로 다운로드
    //   //   e.dataTransfer.setData("DownloadURL", "application/octet-stream:name.png:http://localhost:8080/res/img/logo.png");
    //   // }
    // },
    // dragEnd(e){
    //   e.target.style.opacity = 1.0;
    //   console.log(e)
    // },
  }
};
</script>

<style scoped></style>
