<template>
  <div id="Left" class="layout">
    <div class="profile">
      <div class="photo" :style="{backgroundImage: 'url('+(info.my.profile ? info.my.profile : profile_img)+')'}"></div>
      <div class="info">
        <span>{{info.my.user_name}}</span>
        <span>{{info.my.user_id}}</span>
      </div>
      <i @click="openMyInfo"><f-icon icon="cog"/></i>
    </div>
    <button class="btn_addFolder" @click="openAddFolder">폴더 추가 <f-icon icon="plus"/></button>
    <div class="folder" :class="openMenu=='my'?'open':'close'" @click.right.stop.prevent="openContext($event, 'bg')">
      <h3 :class="activeFolder?'active':''" @click.self="openFolder('root')" @click.right.stop.prevent="openContext($event, null)">개인 폴더<f-icon :icon="openMenu=='my'?'chevron-up':'chevron-down'" @click="openList('my')" /></h3>
      <FolderList parent="root" :depth="1" class="folders" :open="openMenu=='my'"/>
    </div>
    <div class="folder" :class="openMenu=='share'?'open':'close'">
      <h3>공유 폴더<f-icon :icon="openMenu=='share'?'chevron-up':'chevron-down'" @click="openList('share')"/></h3>
<!--      <FolderList :folder="tree.share" :depth="1" class="folders" />-->
    </div>
  </div>
</template>

<script>
import FolderList from "../../components/module/FolderList.vue";
import {mapGetters} from "vuex"
import { EventBus } from "@/utils/EventBus.js";
export default {
  components: { FolderList },
  computed: {
    ...mapGetters({ info: "getSaveInfo"}),
  },
  data() {
    return {
      activeFolder : true,
      profile_img : this.$pd,
      openMenu : "my",
      tree: null,
    };
  },
  created() {
    let objThis = this
    EventBus.$on("bus:openFolder", function(folder_id){
      objThis.activeFolder = folder_id == null || folder_id == 'root' ? true : false
    })
  },
  methods: {
    openMyInfo(){
      EventBus.$emit("bus:openMy");
    },
    openAddFolder(){
      EventBus.$emit("bus:openAddFolder", {code: 'root', name: "/"});
    },
    openFolder(folder_id){
      EventBus.$emit("bus:openFolder", folder_id)
    },
    openContext(e, type, item){
      if(type)
        EventBus.$emit("bus:openContext", {x: e.pageX, y: e.pageY, type: type, target: e.target, item: item})
    },
    openList(p){
      if(this.openMenu == "")
        this.openMenu = p
      else if(this.openMenu == p)
        this.openMenu = ""
      else if(this.openMenu != p)
        this.openMenu = p=="my" ? "my" : "share"
    },
  }
};
</script>

<style scoped></style>
